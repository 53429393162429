import React, { FC, useEffect } from "react"
import { Layout } from "../components/Layout"
import {
  AttributeSection,
  ContactSection,
  DemoButton,
  IntegrationsSection,
} from "../commonComponents"
import {
  IntegrationDarkSection,
  IntegrationServiceSection,
} from "../integrationsUtils"
import { Helmet } from "react-helmet"

export default function Integrations() {
  return (
    <Layout
      keywords="irisagent integration, zendesk integration, freshdesk integration, pagerduty integration, Jira integration"
      title="Enhance Your Workflow with Powerful Integrations With IrisAgent"
      description="Unlock the power integration with essential tools to elevate your customer service. Discover how integrated solutions can enhance your customer support capabilities"
    >
      {/* <!-- ======= Hero Section ======= --> */}
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/integrations/" />
      </Helmet>
      <section
        id="hero-new-design"
        className="section-padding-left d-flex align-items-center"
        style={{ paddingTop: "5%" }}
      >
        <div className="row" style={{ width: "100%", paddingTop: "2%" }}>
          <div
            className="col-md-6 customer-health-first-section-left"
            style={{ zIndex: 1 }}
          >
            <h1 className="title-support-new-design">
              <>
                <span className="title-fucsia-color">Transform</span> Customer
                Support with tools{" "}
                <span className="title-fucsia-color">you already use</span>
              </>
            </h1>
            <div
              className="description-support-new-design web-view-new-design-display"
              style={{ marginTop: "2%" }}
            >
              Automated correlation of tickets, <br />
              alerts and bugs for faster identification
              <br />
              and resolution
            </div>
            <div className="description-support-new-design mobile-view-new-design-display">
              Automated correlation of tickets, <br />
              alerts and bugs for faster identification
              <br />
              and resolution
            </div>
            <br />
            <div style={{ marginTop: "2%", display: "flex" }}>
              <DemoButton />
            </div>
          </div>
          <div className="col-md-6" style={{ marginLeft: "-7%" }}>
            <img
              src="/img/allIntegrationsMain.webp"
              alt="support agent"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>

      {/* <!-- End Hero --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <IntegrationServiceSection
        title={
          <>
            Customer Support
            <br />
            Platforms
          </>
        }
        services={[
          {
            imageSrc: "/img/allintegrations-zendesk-logo.png",
            href: "/zendesk/",
          },
          {
            imageSrc: "/img/allintegrations-intercom-logo.png",
            href: "/intercom/",
          },
          {
            imageSrc: "/img/allintegrations-freshworks-logo.png",
            href: "/freshworks/",
          },
          {
            imageSrc: "/img/allintegrations-salesforce-logo.png",
            href: "/salesforce/",
          },
        ]}
      />
      {/* <!-- End Services Section --> */}

      {/* <!-- ======= Inverted Services Section ======= --> */}
      <IntegrationServiceSection
        inverted={true}
        title={
          <>
            Product and DevOps
            <br />
            Platforms
          </>
        }
        services={[
          {
            imageSrc: "/img/allintegrations-datadog-logo.png",
            href: "/get-a-demo",
            bgColor: "#F2E4FF",
          },
          {
            imageSrc: "/img/allintegrations-pagerduty-logo.png",
            href: "/pagerduty",
            bgColor: "#f0faf1",
          },
          {
            imageSrc: "/img/allintegrations-jira-logo.png",
            href: "/jira",
            bgColor: "#f5f9ff",
          },
          {
            imageSrc: "/img/allintegrations-sentry-logo.png",
            href: "/get-a-demo",
            bgColor: "#f5f5f5",
          },
        ]}
      />
      {/* <!-- End Inverted Services Section --> */}

      {/* //   <!-- ======= Attribute Section ======= --> */}
      <AttributeSection />
      {/* //   <!-- ======= END -- Attribute Section ======= --> */}

      {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Got questions? We'd love to{" "}
            <span className="title-fucsia-color">chat!</span>
          </span>
        }
        button={<DemoButton />}
      />
      {/*  End Cta Section  */}

      {/* <!-- ======= Integrations Section ======= --> */}
      <IntegrationsSection />
      {/* <!-- End Integrations Section --> */}

      {/* <!-- ======= Contact Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Cta Section --> */}
    </Layout>
  )
}
